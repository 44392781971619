module.exports = {
  defaultTitle: 'Matthew Current',
  logo: 'https://portfolio.smakosh.com/favicon/favicon-512.png',
  author: 'Matthew Current',
  url: 'https://portfolio.smakosh.com',
  legalName: 'Matthew Current',
  defaultDescription: 'I’m Matthew and I’m a Backend engineer!',
  socialLinks: {
    twitter: 'http://www.twitter.com/madcurrent',
    github: 'https://github.com/mdcurrent',
    linkedin: 'https://www.linkedin.com/in/mdcurrent/',
    instagram: 'https://instagram.com/mdcurrent',
    youtube: 'https://www.youtube.com/user/mdcurrent',
    google: 'https://plus.google.com',
  },
  googleAnalyticsID: 'UA-88875900-4',
  themeColor: '#45b3e0',
  backgroundColor: '#45b3e0',
  social: {
    facebook: 'appId',
    twitter: '@madcurrent',
  },
  address: {
    city: 'Edgewater',
    region: 'NJ',
    country: 'USA',
    zipCode: '07020',
  },
  contact: {
    email: 'currentmatthew@gmail.com',
    phone: '3098380273',
  },
  foundingDate: '2018',
};
